<template>
  <div class="container">
    <md-card class="dash-content">
      <PageHeader title="Point Orders Management" />
      <div class="loader">
        <Wave v-if="loading || updating" width="50px" color="#d9534f" />
      </div>
      <md-table
        v-model="orders.mdData"
        md-card
        md-sort="title"
        md-sort-order="asc"
      >
        <md-table-toolbar class="mb-4">
          <div class="toolbar-filter-wrapper">
            <md-field md-clearable class="md-toolbar-section-end">
              <md-input placeholder="Search by reference..." v-model="search" />
            </md-field>
            <md-menu md-size="medium" md-align-trigger>
              <md-button
                md-menu-trigger
                class="md-primary button-icon"
                disabled
              >
                Filter
                <img src="@/assets/svg/filter.svg" />
              </md-button>

              <md-menu-content>
                <md-menu-item>Approved</md-menu-item>
                <md-menu-item>Pending</md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
        </md-table-toolbar>

        <md-table-row slot="md-table-row" slot-scope="{ item, index }">
          <md-table-cell md-label="S/N" md-numeric class="text-center"
            >{{ index + 1 }}
          </md-table-cell>
          <md-table-cell md-label="Reference">{{
            item.order_id
          }}</md-table-cell>
          <md-table-cell md-label="Amount">{{
            item.amount | formatMoney
          }}</md-table-cell>
          <md-table-cell md-label="Point">{{ item.point }}</md-table-cell>
          <md-table-cell md-label="Type">{{ item.type }}</md-table-cell>
          <md-table-cell md-label="Created Date">{{
            item.created_at | formatDate
          }}</md-table-cell>
          <md-table-cell md-label="Payment Status">{{
            item.payment_status
          }}</md-table-cell>
          <md-table-cell md-label="Action">
            <md-menu md-size="medium" md-align-trigger>
              <md-button
                v-if="item.payment_status === 'unpaid'"
                class="md-primary button-icon"
                md-menu-trigger
                @click="updateStatus(item)"
                :disabled="updating"
              >
                Mark as Paid
              </md-button>
            </md-menu>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <span v-if="loading" class="b-loading">Loading...</span>
      <pagination
        v-model="orders.mdPage"
        :records="orders.mdCount"
        :per-page="orders.mdPerPage"
        @paginate="updatePagination"
      />
    </md-card>
  </div>
</template>

<script>
import { PageHeader, Wave } from "@/components";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("point");

export default {
  name: "Points Orders",
  components: {
    PageHeader,
    Wave,
  },
  data: () => ({
    search: null,
    fetching: false,
    updating: false,
    exporting: false,
    time: null,
    showStatus: false,
    metadata: true,
    form: {
      point_status: "",
    },
  }),
  watch: {
    search(val) {
      this.waitForIt(val);
    },
  },
  methods: {
    ...mapActions(["getPointOrders", "updatePointStatus"]),

    async updatePagination(page) {
      let data = { page };
      if (this.search) data.keyword = this.search;
      await this.getPointOrders(data);
    },
    async submitSearch() {
      await this.getPointOrders({ keyword: this.search });
    },
    async updateStatus(item) {
      let data = { id: item.id };
      const conf = confirm("Are you sure?");
      if (!conf) {
        return;
      }
      try {
        this.updating = true;
        await this.updatePointStatus(data);
        this.updating = false;
        this.toast("success", "Point Status Updated ");
        this.getPointOrders();
      } catch (err) {
        this.updating = false;
        this.toast("error", "Opps, Something went wrong");
      }
    },
    async setShowStatus(id) {
      this.showStatus = true;
      this.fetching = true;
      let metadata = await this.getPointMetadata(id);
      this.fetching = false;
      this.metadata = metadata;
    },
    waitForIt(val) {
      if (this.time) {
        clearTimeout(this.time);
      }
      this.time = setTimeout(() => this.submitSearch(val), 1000);
    },
  },
  created() {
    this.searched = this.users;
  },
  mounted() {
    this.getPointOrders({});
  },
  computed: {
    ...mapGetters(["loading", "orders"]),
    hasPermission() {
      return this.$store.getters["auth/has_module_permission"]("crud");
    },
  },
};
</script>

<style lang="scss" scoped>
// .md-field {
//   max-width: 500px;
// }
.md-table-cell {
  width: 0% !important;
  padding: 0rem 0.8rem;
}

.md-table + .md-table {
  margin-top: 16px;

  thead {
    height: 50px !important;
  }
}

.badge {
  background: #f3f3f3;

  &.success {
    background: #5cb85c;
  }

  &.danger {
    background: #d9534f;
  }
}

.modal-contain {
  max-width: 100%;
  width: 600px;
  padding: 20px;
  overflow-y: auto;

  .item {
    display: flex;
    gap: 20px;
    padding: 10px 10px;
    margin: 5px 0px;

    &:nth-child(odd) {
      background: #00ff1f0d;
    }
  }
}
.loader {
  position: absolute;
  top: 24px;
  right: 30px;
}
</style>
